import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { HeaderWrapper, OverlayDashboard } from '../../../HealthTabV2/styles'
import { MainEventsContainer } from '../../../MainEvents/styles'
import { BuddyTab, BuddyTabContainer } from '../../../PeopleHomeV2/styles'
import HoverButton from '../../../common/HoverButton'
import { BackIcon, DownloadIconNew, 
} from '../../../../utils/icons';
import CSVList from '../../../AttendeesModal/CSVList';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { /*GetSurveyQuestionInsights,*/ FetchExcelSurveyReport, FetchSurveyQuestionInsights } from '../../../../redux/constants/apiConstants';
import { toast } from 'react-toastify';
import DropDown from '../../../common/CommonDropDown/DropDown';
import { AnswerCard, AnswerWrapper, AssesmentWrapper, CircleNew, CircularGraph, InsightReport, MultipleAnswerCard, QuestionsCard } from './style';
import { NoData } from '../../../ReportsV2/styles';
import CircularProgressBar from '../../../HealthTabV2/HealthTabUpdated/circularProgressBar';
import { ProgressBar } from '../../../HealthTabV2/HealthTabUpdated/styles';
import VerticalBarChart from '../../../VerticalBarChart/VirticalBarChart';
import LazyImage from '../../../common/LazyImage/LazyImage';
import { ImageUrl } from '../../../../utils/constants';
import ExcelJS from 'exceljs/dist/exceljs';
import { fetchApi } from '../../../../utils/methods';
import SkeletonLoder from '../../../common/skeletonLoder';

const filetrOptions = [
  { value: 0, text: "All Scores",key:'normal' },
  { value: 1, text: "Low - High Score", key:'ascending' },
  { value: 2, text: "High - Low Score", key:'descending' },
];

// export const getSurveyReportData = async (surveyId) => {
//   const AuthToken = localStorage.getItem('AUTH_TOKEN');
//   let url = BASE_URL + GetSurveyReport + "/" + surveyId + "/" + 0 + "/" + 0;

//   try {
//     const response = await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } });

//     if (response.status === 200) {
//       const data = await response.json();
//       Object.keys(data).length  ?  exportToExcel(data) : toast.warn("Data Not Found")
//       return data;
//     } else {
//       toast.error(`Request failed with status: ${response.status}`)
//       // throw new Error(`Request failed with status: ${response.status}`);
//     }
//   } catch (error) {
//     toast.error(`Request failed with status: ${error.message}`)
//     // throw new Error(`Error during fetch: ${error.message}`);
//   }
// };

export const getSurveyReportData = async (surveyId,companyId, department, location) => {
  // setIsLoading(true);
  let url = FetchExcelSurveyReport + surveyId + "/" + companyId + "/" + (department ?  department : 0) +  "/" + (location ?  location : 0);
  try {
    const res = await fetchApi(`${url}`, "GET");
    window.console.log(res);
    if (res?.data?.message) {
      toast.error(res?.data?.message);
      // setIsLoading(false);
    } else {
      Object.keys(res?.data).length  ?  exportToExcel(res?.data) : toast.warn("Data Not Found")
    }
  } catch (error) {
    // setIsLoading(false);
    toast.error(error.message);
  }
};

export const exportToExcel = (data)=> {

  const workbook = new ExcelJS.Workbook();

  const worksheet = workbook.addWorksheet('Question Insight');
  let firstRow = [];
  let secondRow = [];
  data.questions.forEach((question, index)=>{
    let firstRowData = {};
    let secondRowData = {};
    firstRowData.header = question.question_order + "";
    firstRowData.key = question.question;
    firstRowData.width= 50;
    firstRow.push(firstRowData);

    secondRowData.header = `Question ${index}`
    firstRowData.width= 50
    secondRow.push(question.question);

  });
  
  firstRow.splice(0,0,{header:"Question Number",width:50});
  for (let i = 0; i < 6; i++) {
    // firstRow.push({header:"",width:30,style: { font: { bold: i < 4 && true } }});
    firstRow.push({header:"",width:30,style: { font: { bold: i < 4 && false } }});
  }
  secondRow.splice(0,0,"People");
  secondRow.push("User Total","Total Questions Pts","Average","Status","Department","Location")
  worksheet.columns =firstRow;
  worksheet.insertRow(2, secondRow);

  let thirdRow = [];

  let keys = Object.keys(data.user_answers);

  // window.console.log("keys",keys)

  for (let i = 0; i < keys.length; i++) {

    let answersLength = data.user_answers[keys[i]].length-1;

    thirdRow.push(i+1 + "");
    for (let j = 0; j < data.user_answers[keys[i]].length; j++) {
      thirdRow.push(data.user_answers[keys[i]][j].answer + "");
    }
    thirdRow.push(data.user_answers[keys[i]][answersLength].user_total + "",data.user_answers[keys[i]][answersLength].question_max_total + "",data.user_answers[keys[i]][answersLength].average + "",data.user_answers[keys[i]][answersLength].status + "",data.user_answers[keys[i]][answersLength].department + "",data.user_answers[keys[i]][answersLength].city + "")
    worksheet.insertRow(i+3, thirdRow);
    thirdRow=[]
  }

  let fourthRow = ["Total Points"];
  let fifththRow = ["Question Point Weightage"];
  let sixthRow = ["Total Responses"];
  let seventhRow = ["Total Points Availble"];
  let eighthRow = ["Average Score"];

  const row = worksheet.lastRow;


  for (let k = 0; k < data.user_sums.length; k++) {
    fourthRow.push(data.user_sums[k].total_points + "");
    fifththRow.push(data.user_sums[k].question_point_weighage + "");
    sixthRow.push(data.user_sums[k].total_responses + "");
    seventhRow.push(data.user_sums[k].total_point_value + "");
    let avgScore = Math.round((data.user_sums[k].total_points/data.user_sums[k].total_point_value)*100);
    eighthRow.push(avgScore === Infinity || isNaN(avgScore) ? "0%" : (avgScore  + "%"));
  }
  worksheet.insertRow(row._number + 1,"");
  worksheet.insertRow(row._number + 2,fourthRow);
  worksheet.insertRow(row._number + 3,fifththRow);
  worksheet.insertRow(row._number + 4,sixthRow);
  worksheet.insertRow(row._number + 5,seventhRow);
  worksheet.insertRow(row._number + 6,eighthRow);
  fourthRow=[];
  fifththRow=[];
  sixthRow=[];
  seventhRow=[];
  eighthRow=[];
  // Set Row 2 to Comic Sans.
  worksheet.getRow(1).font = { bold: true };
  worksheet.getRow(2).font = { bold: true };
  worksheet.getRow(row._number + 2).font = { bold: true };
  worksheet.getRow(row._number + 3).font = { bold: true };
  worksheet.getRow(row._number + 4).font = { bold: true };
  worksheet.getRow(row._number + 5).font = { bold: true };
  worksheet.getRow(row._number + 6).font = { bold: true };
   
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new window.Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = "Survey Report";

    // Programmatically trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}

const QuestionInsight = (props) => {
  const{history, departmentDetails, locationDetails,/*role,*/companyInfo} = props;
  const[isLoading, setIsLoading] = useState(false);
  // const[questionInsight, setQuestionInsight] = useState([]);
  const[questionInsightData, setQuestionInsightData] = useState([]);
  const[insightReport, setInsightReport] = useState({avgScore:0,members:0,responses:0,feedbacks:0});
  const[printCSV,setPrintCSV] = useState(false);
  const[activeOption, setActiveOption] = useState({showOption: null, displayPercentage: false, selectdQuestionId:null});
  const[sortValue, setSortValue] = useState({ value: 0, text: "All Scores",key:'normal' });
  const[selectedDepartment, setSelectedDepartment] = useState({ department: "All Department", id: null });
  const[selectedLocation, setSelectedLocation] = useState({ location: "All Locations", id: null });
  const[FeedbackQuestions,setFeedbackQuestions] = useState([]);
  // const[SurveyReportData,setSurveyReportData]= useState([]);

  const componentMounted = useRef(true); // (3) compo


  useEffect(()=>{
    // setIsLoading(false);
    // getSurveyReport(props.match.params.id);
    getQuestionInsights(props.match.params.id);
    setPrintCSV(false);
    // const data = role === "ADMIN" && await getSurveyReportData(props.match.params.id);

    // fetchExcelData();

  

    return () => { // This code runs when component is unmounted
      componentMounted.current = false; // (4) set it to false when we leave the page
    }

  },[]);

  // const fetchExcelData = async () => {
  //   try {
  //     const data = await getSurveyReportData(props.match.params.id);

  //     if (componentMounted.current) {
  //       setSurveyReportData(data);
  //     }
  //   } catch (error) {
  //     toast.error(error.message);
  //   } finally {
  //     window.console.log("data")

  //   }
  // }
  
  const handleMouseEnter = (index, id) => {
    setActiveOption({ showOption: index, displayPercentage: true , selectdQuestionId:id})
  }

  const handleMouseLeave = (index) => {
    setActiveOption({ showOption: index, displayPercentage: false, selectdQuestionId:null })
  }

  // const getSurveyReport = async(surveyId, department, location) => {
  //   setIsLoading(true);
  //   const AuthToken = localStorage.getItem('AUTH_TOKEN');

  //   let url = BASE_URL + GetSurveyQuestionInsights +"/" + surveyId + "/" + (department ?  department : 0) +  "/" + (location ?  location : 0);
  //   await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } }).then(
  //     (res) => {
  //       res.json().then((data) => {
  //         if(res.status === 200){
  //           if (componentMounted.current){ 
  //             const dataWithQuestionNumber = data.question_insights.map((questionData, index) => ({
  //               questionNumber: index + 1, // Add the question number (index + 1)
  //               ...questionData, // Spread the original question data
  //             }));

  //             const updatedData = dataWithQuestionNumber.map((item) => {
  //               const updatedAnswers = item.question.answers.map((answer,) => {
  //                 const answerPercentage = item.answer_percentages.find(
  //                   (percentage) => percentage.wlb_survey_answer_id === answer.answer_id
  //                 );
              
  //                 return {
  //                   ...answer,
  //                   count: answerPercentage ? answerPercentage.count : 0,
  //                   percentage: answerPercentage ? answerPercentage.percentage : 0,
  //                 };
  //               });
              
  //               const updatedAnswerPercentages = item.question.answers.map((answer) => {
  //                 const answerPercentage = item.answer_percentages.find(
  //                   (percentage) => percentage.wlb_survey_answer_id === answer.answer_id
  //                 );
              
  //                 return {
  //                   wlb_survey_answer_id: answer.answer_id,
  //                   count: answerPercentage ? answerPercentage.count : 0,
  //                   percentage: answerPercentage ? answerPercentage.percentage : 0,
  //                   totalPoints: answer.points,
  //                 };
  //               });
              
  //               return {
  //                 ...item,
  //                 question: {
  //                   ...item.question,
  //                   answers: updatedAnswers,
  //                 },
  //                 answer_percentages: updatedAnswerPercentages,
  //               };
  //             });


  //             setQuestionInsight(updatedData);
  //             setShortQuestion(updatedData)
  //             setIsLoading(false);
  //           }

  //         }else{
  //           toast.error(res.status);
  //         }

  //       }).catch((error)=>{
  //         toast.error(error);
  //       });
  //     }
  //   ).catch((error)=>{
  //     toast.error(error);
  //   });
  // };

  const getQuestionInsights = async (surveyId, department, location) => {
    setIsLoading(true);
    let url = FetchSurveyQuestionInsights + surveyId + "/" + companyInfo.id + "/" + (department ?  department : 0) +  "/" + (location ?  location : 0);
    try {
      const res = await fetchApi(`${url}`, "GET");
      window.console.log(res);
      if (res.message) {
        toast.error(res.message);
        setIsLoading(false);
      } else {
        setQuestionInsightData(res?.data?.question_insights);
        window.console.log(questionInsightData)
        setInsightReport({avgScore:res?.data?.total_average_score_percent,members:res?.data?.total_members,responses:res?.data?.total_responses,feedbacks:res?.data?.total_feedback_responses});
        setShortQuestion(res?.data?.question_insights)
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  const setShortQuestion = (data)=>{
    // Filter the 'answers' array to get only short-answer questions
    const shortAnswerQuestions = data.filter((que) => {window.console.log("hi");return que.type === 'short-answer'});
      
    // Create an array of objects with the desired format
    const formattedData = shortAnswerQuestions.map((answer) => {
      window.console.log("hi");
      return {
        value: answer.question_id,
        text: `Question ${answer.weight}`,
        que: answer.question,
      }});

    setFeedbackQuestions(formattedData)

  }

  function getColorByIndex(index) {
    // Define an array of colors or generate colors dynamically
    const colors = [
      '#76AB78',
      '#85C0EA',
      '#F1C977',
      '#AF87C1',
      '#F4AAA9',
    ];
  
    // Return the color based on the index, repeating if necessary
    return colors[index % colors.length];
  }
  

  const graphRender = (data, id, index) => {
    // const topActivities = [{ y: data[0]['percentage'], color: 'rgba(157, 135, 169, 1)' }, { y: data[1]['percentage'], color: '#FD7175' }, { y: data[2]['percentage'], color: '#F6B479' }, { y: data[3]['percentage'], color: '#69C2FF' }, { y: data[4]['percentage'], color: '#9FC989' }];
    // const topActivities = [{ y: data[0]['percentage'], color: 'rgba(157, 135, 169, 1)' }, { y: data[1]['percentage'], color: '#FD7175' }];
    // const xAxisLabels = [questionInsightData[index].question.answers[0]['value'], questionInsightData[index].question.answers[1]['value'], questionInsightData[index].question.answers[2]['value'], questionInsightData[index].question.answers[3]['value'], questionInsightData[index].question.answers[4]['value']];

    // const updatedAnswerPercentages = [...data];

    // Iterate through answers and add missing entries to answer_percentages

    // questionInsightData[index].question.answers.forEach((answer, index) => {
    //   if (index >= updatedAnswerPercentages.length) {
    //     updatedAnswerPercentages.push({
    //       "wlb_survey_answer_id": answer.answer_id,
    //       "count": 0,
    //       "percentage": 0
    //     });
    //   }
    // });

    const topActivities = questionInsightData[index].answers.map((item, answerIndex) => ({
      y: Math.round(item.selected_answer_percentage),
      color: questionInsightData[index].type === "opinion-scale" ? (answerIndex < 5 ? "#F4AAA9" : answerIndex > 4 && answerIndex < 8 ? "#F1C977" :"#76AB78" ) : getColorByIndex(answerIndex), // You can define getColorByIndex function
    }));
    const xAxisLabels = questionInsightData[index].answers.map((answer) => answer.value);
    const yAxisLabels = ['20%', '40%', '60%', '80%', '100%'];
    const dataValue = [{
      name: '',
      data: topActivities,
      showInLegend: false,
      color: '#DE9444'
    }];
    return (
      <div className="bargraph">
        <VerticalBarChart
          id={`${id} ${1}`}
          seriesData={dataValue}
          xAxisLabel={xAxisLabels}
          yAxisVisible={yAxisLabels}
          fillColor={1}
          cultureSurvey={1}
          width={(questionInsightData[index].type === "opinion-scale" || questionInsightData[index].type === "likert-scale") && "30px"}
        />
      </div>)
  }


  const sortBy = (value)=>{
    const originalData = [...questionInsightData];
    if(value.key === "ascending"){
      const sortedData = originalData.slice().sort((a, b) => a.score_percentage - b.score_percentage);
      setQuestionInsightData(sortedData); 
    }else if(value.key  === "descending"){
      const sortedData = originalData.slice().sort((a, b) => b.score_percentage - a.score_percentage);
      setQuestionInsightData(sortedData); 
    }else {
      
      const sortedData = originalData.sort((a, b) => Number(a.weight) - Number(b.weight));
      setQuestionInsightData(sortedData); 

    }
    setSortValue(value)
  }

  // const getSurveyReportData = async(surveyId) => {
  //   // setIsLoading(true);
  //   const AuthToken = localStorage.getItem('AUTH_TOKEN');

  //   let url = BASE_URL + GetSurveyReport +"/" + surveyId + "/" + 0 + "/" + 0;
  //   await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } }).then(
  //     (res) => {
  //       res.json().then((data) => {
  //         if(res.status === 200){
  //           if (componentMounted.current){ 
  //             setSurveyReportData(data);
  //             return data
  //             // this.setState({surveyReportData:data})
  //             // window.console.log("data",data)
  //           }

  //         }else{
  //           toast.error(res.status);
  //         }

  //       }).catch((error)=>{
  //         toast.error(error);
  //       });
  //     }
  //   ).catch((error)=>{
  //     toast.error(error);
  //   });
  // };


  return (
    <React.Fragment>
      <OverlayDashboard>
        <MainEventsContainer marginTop="1">
          <HeaderWrapper padding={1} style={{padding:'0px'}}>
            <BuddyTabContainer marginBottom="25px" style={{alignItems:'center'}}>
              <HoverButton
                title="Back"
                width="24px"
                height="24px"
                svgPath={BackIcon()} 
                onClick={()=>{ history?.location?.state?.page === "completed" ? history.push("/company/surveys/completed") :  history.push("/company/surveys");}}
              />
              <BuddyTab
                width
                padding
                style={{margin:"0px"}}
                // active={activeTab===1 ? true : false}
                // onClick={() => setActiveTab(1)}
                onClick={()=>{ history?.location?.state?.page === "completed" ? history.push({pathname:`/company/survey-analytics/${props.match.params.id}`,state:{page:"completed"}}) :  history.push(`/company/survey-analytics/${props.match.params.id}`)}}
              >
                {"Analytics"}
              </BuddyTab>
              <BuddyTab
                width
                padding
                style={{margin:"0px"}}
                active={true}
                // onClick={() => setActiveTab(2)}
                onClick={()=>history.push(`/company/survey-insight/${props.match.params.id}`)}
              >
                {"Question Insight"}
              </BuddyTab>
              <BuddyTab
                width
                padding
                style={{margin:"0px"}}
                // active={activeTab===3 ? true : false}
                // onClick={() => setActiveTab(3)}
                onClick={()=>{ history?.location?.state?.page === "completed" ? history.push({pathname:`/company/survey-feedback/${props.match.params.id}`,state:{page:"completed"}}) :  history.push(`/company/survey-feedback/${props.match.params.id}`)}}
              >
                {"Feedback"}
              </BuddyTab>
              <div style={{display:"flex",flexDirection:"row",alignItems:"center", justifyContent:"end", width:"62%", marginRight:"10px"}}>
                {/* <span style={{color:"rgba(0, 92, 135, 0.6)",fontSize:"16px",lineHeight:"20px",marginRight:"10px"}}>Filter</span> */}
                <div style={{marginRight:"15px"}}>
                  <DropDown
                    data={departmentDetails || [{department:"Department", id:0}]}
                    placeholder="Select Option"
                    valueIndex={0}
                    dropdownStyle={{ top: "30px",height:"40px"}}
                    onSelectParameter={["department", "id"]}
                    itemValue={true}
                    activeValue={true}
                    title={selectedDepartment.department}
                    displayValue={true}
                    valueString={""}
                    tileContainerStyle={{
                      width: "auto",
                      background: "rgba(247, 245, 235, 0.50)",
                      position: "absolute",
                      height:"40px",
                      padding:"0px"
                    }}
                    active={selectedDepartment.id}
                    onSelect={(value) => {
                      setSelectedDepartment(value);
                      getQuestionInsights( props.match.params.id,value.id,selectedLocation.id);
                      // sortBy(value)
                    }}
                    activityDropdownStyle={{
                      background: "rgba(247, 245, 235, 0.50)",
                      height:"40px"
                    }}
                    height={"40px"}
                  />
                </div>

                <div style={{marginRight:"15px"}}>
                  <DropDown
                    data={locationDetails || [{location:"All Locations", id:0}]}
                    placeholder="Select Option"
                    valueIndex={0}
                    dropdownStyle={{ top: "30px",height:"40px", marginRight:"15px"}}
                    onSelectParameter={["location", "id"]}
                    itemValue={true}
                    activeValue={true}
                    title={selectedLocation.location}
                    displayValue={true}
                    valueString={""}
                    tileContainerStyle={{
                      width: "auto",
                      background: "rgba(247, 245, 235, 0.50)",
                      position: "absolute",
                      height:"40px",
                      padding:"0px"
                    }}
                    active={selectedLocation.id}
                    onSelect={(value) => {
                      setSelectedLocation(value);
                      getQuestionInsights( props.match.params.id,selectedDepartment.id,value.id);
                      // sortBy(value);
                    }}
                    activityDropdownStyle={{
                      background: "rgba(247, 245, 235, 0.50)",
                      height:"40px"
                    }}
                    height={"40px"}
                  />
                </div>
                <DropDown
                  data={filetrOptions}
                  placeholder="Select Option"
                  valueIndex={0}
                  dropdownStyle={{ top: "30px",height:"40px"}}
                  onSelectParameter={["text", "value"]}
                  itemValue={true}
                  activeValue={true}
                  title={sortValue.text}
                  displayValue={true}
                  valueString={""}
                  tileContainerStyle={{
                    width: "auto",
                    background: "rgba(247, 245, 235, 0.50)",
                    position: "absolute",
                    height:"40px",
                    padding:"0px"
                  }}
                  active={sortValue?.value}
                  onSelect={(value) => {
                    sortBy(value)
                  }}
                  activityDropdownStyle={{
                    background: "rgba(247, 245, 235, 0.50)",
                    height:"40px"
                  }}
                  height={"40px"}
                />
  
                {/* <HoverButton title={"Download"} 
                  onClick={()=> getSurveyReportData(props.match.params.id)} 
                  svgPath={PrintIcon()} style={{cursor:questionInsightData.length && "pointer"}}/> */}
                <HoverButton style={{cursor:questionInsightData?.length && "pointer"}} onClick={()=> getSurveyReportData(props.match.params.id, companyInfo.id,selectedDepartment.id,selectedLocation.id)}  width={"40px"} height={"40px"} title={"Download"} fillOpacity={1} disabled={false} backgroundColor={"#f0f6f8"} backgroundColorActive={"#005c87"} activeColor={"#619ab5"} inActiveColor={"white"} svgPath={<DownloadIconNew/>} />
              </div>


            </BuddyTabContainer>

            {printCSV &&
                    <CSVList
                      // Title={`${history.location.state.surveyName}_report`}
                      // removeCSVData={downloadEnrolledList}
                      // headers={surveyReportsHeader}
                      // data={surveyReportsData} 

                    />
            }

            {isLoading ? <SkeletonLoder width={"1248px"} height={"100vh"}/> :<AssesmentWrapper>
              <div className="reportCard">
                <InsightReport >
                  <div className="header">
                    Insight Report
                  </div>
                  <div className="body">
                    <div className="card-style">
                      <div className='img'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.778 0H2.222A2.225 2.225 0 0 0 0 2.222v19.556A2.225 2.225 0 0 0 2.222 24h19.556A2.225 2.225 0 0 0 24 21.778V2.222A2.225 2.225 0 0 0 21.778 0ZM7.556 2.667a4 4 0 1 1 0 7.998 4 4 0 0 1 0-7.998ZM4.889 21.333a1.332 1.332 0 0 1-.996-2.219l14.222-16a1.333 1.333 0 0 1 1.993 1.772l-14.222 16a1.335 1.335 0 0 1-.997.447Zm11.555 0a4 4 0 1 1 0-7.998 4 4 0 0 1 0 7.998Z" fill="#005C87"/>
                      </svg></div>
                      <div className="name">Average Score</div>
                      <div className="score">{Math.round(insightReport.avgScore) + "%" || "-"}</div>
                    </div>
                    <div className="card-style">
                      <div className='img'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <mask id="a" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                            <path fill="#D9D9D9" d="M0 0h24v24H0z"/>
                          </mask>
                          <g mask="url(#a)" fill="#005C87">
                            <path d="M5.53 8.505c1.437 0 2.6-1.232 2.6-2.752C8.13 4.233 6.968 3 5.53 3c-1.436 0-2.6 1.232-2.6 2.753 0 1.52 1.164 2.752 2.6 2.752Zm4.702 6.033c-1.1-.635-1.8-1.905-1.8-3.282 0-.159 0-.37.05-.53A5.5 5.5 0 0 0 5.58 9.88c-2.7 0-4.901 1.958-5.551 4.605-.15.53.3 1.06.8 1.06h7.402c.55-.477 1.25-.795 2-1.007Zm8.201-6.033c1.436 0 2.6-1.232 2.6-2.752 0-1.52-1.164-2.753-2.6-2.753-1.437 0-2.601 1.232-2.601 2.753 0 1.52 1.164 2.752 2.6 2.752Zm5.551 5.927c-.6-2.647-2.85-4.606-5.501-4.606a5.5 5.5 0 0 0-2.9.847c.05.159.05.318.05.53 0 1.429-.75 2.646-1.801 3.282.75.211 1.4.529 2 1.005h7.402c.45 0 .85-.529.75-1.058Zm-12.003-.477c1.437 0 2.601-1.233 2.601-2.753s-1.164-2.753-2.6-2.753c-1.437 0-2.601 1.233-2.601 2.753s1.164 2.753 2.6 2.753Zm.001 1.379c-2.701 0-4.902 1.959-5.502 4.605-.1.53.3 1.06.8 1.06h9.453c.55 0 .95-.53.8-1.06-.65-2.646-2.85-4.605-5.552-4.605Z"/>
                          </g>
                        </svg>
                      </svg></div>
                      <div className="name">Total Members</div>
                      <div className="score">{insightReport.members || "-"}</div>
                    </div>
                    <div className="card-style">
                      <div className='img'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.994 9.628a8.2 8.2 0 1 0-11.41 11.776.3.3 0 0 1 .077.326l-.72 2.004 2.121-1.074a.3.3 0 0 1 .282.006 8.199 8.199 0 0 0 9.65-1.445 8.2 8.2 0 0 0 0-11.593Zm4.955-1.34c-1.783-3.372-5.62-6.179-11.886-7.164a.281.281 0 0 1-.25-.277L6.792 0l-5.17 3.09 5.259 3.2-.022-1.186a.288.288 0 0 1 .32-.254C21.502 6.505 20.749 19.128 12.362 24c2.73-1.118 4.811-2.956 6.129-5.116.99-1.622 1.555-3.421 1.644-5.242a10.203 10.203 0 0 0-1.185-5.354ZM4.023 11.986c-.404 0-.404-.614 0-.614h3.405c.404 0 .404.614 0 .614H4.023Zm-.37 2.392c-.405 0-.405-.615 0-.615h9.25c.405 0 .405.615 0 .615h-9.25Zm.077 2.657c-.404 0-.404-.614 0-.614h9.09c.405 0 .405.614 0 .614H3.73Zm1.567 2.713c-.405 0-.405-.614 0-.614H9.87c.404 0 .404.614 0 .614H5.297Z" fill="#005C87"/>
                        </svg>
                      </svg></div>
                      <div className="name">Total Responses</div>
                      <div className="score">{insightReport.responses || "-"}</div>
                    </div>
                    <div className="cardNoBorder">
                      <div className='img'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M5.775 0h16.617C23.277 0 24 .724 24 1.608v12.31c0 .832-.64 1.522-1.453 1.6a.748.748 0 0 0-.678.746v2.465c0 .32-.191.594-.493.704a.739.739 0 0 1-.83-.22l-2.886-3.421a.727.727 0 0 0-.573-.267h-.457a.376.376 0 0 1-.375-.375v-2.63a2.65 2.65 0 0 0-2.646-2.645H4.542a.376.376 0 0 1-.375-.375V1.608C4.167.723 4.891 0 5.775 0ZM1.597 10.924h12.012a1.6 1.6 0 0 1 1.597 1.597v6.509c0 .878-.718 1.597-1.597 1.597h-7.1c-.199 0-.393.048-.515.205l-2.265 2.887c-.24.305-.587.336-.868.215a.736.736 0 0 1-.453-.689v-1.868a.751.751 0 0 0-.75-.75h-.061A1.602 1.602 0 0 1 0 19.03v-6.51c0-.878.719-1.596 1.597-1.596Zm1.867 3.384a1.312 1.312 0 1 1 0 2.625 1.312 1.312 0 0 1 0-2.625Zm4.14 0a1.312 1.312 0 1 1-.001 2.624 1.312 1.312 0 0 1 0-2.624Zm4.138 0a1.313 1.313 0 1 1 0 2.625 1.313 1.313 0 0 1 0-2.625ZM8.082 3.825h12.003a.47.47 0 0 1 .468.468v.5a.47.47 0 0 1-.468.468H8.082a.47.47 0 0 1-.468-.468v-.5c0-.258.21-.468.468-.468Zm0 3.22h8.653c.257 0 .467.21.467.467v.501c0 .257-.21.467-.467.467H8.082a.469.469 0 0 1-.468-.467v-.5c0-.258.21-.468.468-.468Z" fill="#005C87"/>
                        </svg>
                      </svg></div>
                      <div className="name">Total Feedbacks</div>
                      <div className="score">{insightReport.feedbacks || "-"}</div>
                    </div>
                  </div>
                </InsightReport>
                {/* <CategoryScoreCard color={healthWellnessScore[indexValue]['background']}>
                  <div className="categoryDetails">
                    <LazyImage div={'image'} src={ImageUrl + healthWellnessScore[indexValue]['icon']} alt={"arrow-up"} />
                    <div className="name"> Company {healthWellnessScore[indexValue]['name']}<br />Score</div>
                  </div>
                  <ProgressBar height={"25px"} background={"rgba(156,156,156,.20)"} backgroundActive={healthWellnessScore[indexValue]['background']} width={(healthWellnessScore[indexValue]['earned']*100)/healthWellnessScore[indexValue]['total']} paddingRight="0px">
                    <div className="progress"></div>
                  </ProgressBar>
                  <div className="score">
                    <div className="value">{healthWellnessScore[indexValue]['earned']?healthWellnessScore[indexValue]['earned']:0}/{healthWellnessScore[indexValue]['total']?healthWellnessScore[indexValue]['total']:0}</div>
                    <div className="value">{healthWellnessScore[indexValue]['earned']?`${Math.round(healthWellnessScore[indexValue]['earned']*100/healthWellnessScore[indexValue]['total'])}%`:0}</div>
                  </div>
                </CategoryScoreCard> */}
              </div>
              {questionInsightData && questionInsightData?.length === 0 ?
                <NoData width="100%" style={{color:"rgba(0,92,135,1)", flexDirection:"column"}}>
                  <LazyImage style={{width:"150px",height:"150px",marginBottom:"20px"}} src={ImageUrl + "/admin-survey/approved.png"}/>
                  <div>Your employee has not yet responded to the survey.<br/> Kindly encourage them to participate.</div></NoData> :
                <div className="questionCard">
                  {questionInsightData && questionInsightData?.length > 0 && questionInsightData.map((data, index) => (
                    data.graph_type === 1 ?
                      <QuestionsCard key={index} width={data.is_multiple_answers} isWordBreak={true}>
                        <div className="question">
                          <div className='que'>{`Q${data.weight}. ${data.question}`}</div>
                          <div className="que-type">{data.type}</div>
                        </div>
                        <div className="body">
                          <div className="answers">
                            <AnswerWrapper display="flex" wrap="wrap" padding="25px 25px 15px 25px">
                              {data?.answers?.map((ans, index) => (
                                <MultipleAnswerCard key={index} color={ans.color} margin={(index + 1) % 3 == 0 ? "0px" : "15px"} backgroundActive={ans.graph_color}>
                                  <div className="option">{ans.answer}</div>
                                  <div className="bar">
                                    <ProgressBar height={"16px"} backgroundActive={ans.graph_color} background={ans.background_color} width={ans?.selected_answer_percentage} paddingRight="0px">
                                      <div className="progress"></div>
                                      <div className="percentageValue">{ans?.selected_answer_percentage>0?`${ans?.selected_answer_percentage}%`:null}</div>
                                    </ProgressBar>
                                  </div>
                                </MultipleAnswerCard>))}
                            </AnswerWrapper>
                          </div>
                        </div>
                      </QuestionsCard> :
                      data.type === "yes-no" ?
                        <QuestionsCard key={index} isWordBreak={true} >
                          <div className="question">
                            <div className='que'>{`Q${data.weight}. ${data.question}`}</div>
                            <div className="que-type">Yes/No</div>
                          </div>
                          <div className="body">
                            <div className="circularPercentage">
                              <CircleNew width="170px" height="170px">
                                <CircularProgressBar
                                  strokeWidth="17"
                                  sqSize="170"
                                  percentage={data.score_percentage}
                                  color="#005C87"
                                  textValue="Average Score"
                                />
                              </CircleNew>
                              <div className='score-details'>
                                <div>
                                  <div className='row'>
                                    <div className='circle'></div>
                                    <div><span className='count'>{data.response_count}</span> <span className='resp'>Responses</span></div>
                                  </div>
                                  {/* <div className='row'>
                                    <div className='circle'></div>
                                    <div><span className='count'>{data.skipped_response_count}</span> <span className='resp'>Skipped Questions</span></div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                            <div className="answers">
                              <AnswerWrapper display="flex" wrap="wrap" padding="25px 25px 15px 25px">
                                <div className="circleWrapper">
                                  {data?.answers?.map((ans, index) => (
                                    <CircularGraph key={index}  backgroundActive={ans.graph_color} color={ans?.selected_answer_percentage>30?"white":ans.graph_color} circleWidth={(ans?.selected_answer_percentage * 0.36) * 10} background={ans.value.toLowerCase().includes("yes") ? "#76AB78" : "#F4AAA9"}  onMouseEnter={() => handleMouseEnter(index, data.question_id)} onMouseLeave={() => handleMouseLeave(index, data.question_id)}>
                                      <div className="circleContainer">
                                        <div className="circle">
                                          {activeOption.displayPercentage && index == activeOption.showOption&& activeOption.selectdQuestionId===data.question_id&&
                                          <div className="hidePercentCard">
                                            <div className="showPercent">{(Math.round(ans?.selected_answer_percentage))}%</div>
                                          </div>}
                                        </div>
                                      </div>
                                      <div className="option">{ans.value}</div>
                                    </CircularGraph>))}
                                </div>
                              </AnswerWrapper>
                              {/* {data.point_status?<div className="circularGraphPercent">
                                <ProgressBar marginBottom="10px" height={"16px"} backgroundActive={"#005C87"} width={"60"} paddingRight="0px">
                                  <div className="progress"></div>
                                </ProgressBar>
                                <span className="value">{data.score_percentage}%</span>
                                <span className="average">Average Score</span>
                              </div>:null} */}
                            </div>
                          </div>
                        </QuestionsCard> :
                        data.type === "short-answer" ?
                          <QuestionsCard key={index} width={data.graph_type === 5||data.graph_type === 4} isWordBreak={true}>
                            <div className="question">
                              <div className='que'>{`Q${data.weight}. ${data.question}`}</div>
                              <div className="que-type">{"Short Answer"}</div>
                            </div>
                            <div className="body" >
                              {/* <div className="circularPercentage">
                                <CircleNew width="170px" height="170px">
                                  <CircularProgressBar
                                    strokeWidth="17"
                                    sqSize="170"
                                    percentage={data.score_percentage}
                                    color="#005C87"
                                    textValue="Average Score"
                                  />
                                </CircleNew>
                                <div className='score-details'>
                                  <div>
                                    <div className='row'>
                                      <div className='circle'></div>
                                      <div><span className='count'>{data.response_count}</span> <span className='resp'>Responses</span></div>
                                    </div>
                                    <div className='row'>
                                      <div className='circle'></div>
                                      <div><span className='count'>{data.skipped_response_count}</span> <span className='resp'>Skipped Questions</span></div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                              <div className="answers" style={{width:"100%"}}>
                                <AnswerWrapper style={{display:'flex',flexDirection:'column',alignItems:"center", justifyContent:"center", padding:"0px"}}>
                                  <div style={{marginBottom:"20px"}}><span style={{color:"rgba(0, 92, 135, 1)", fontFamily:"Rubik",fontSize:"18px",lineHeight:"24px"}}>See all the Answers</span></div>
                                  <div><button onClick={()=>history.push({pathname:`/company/survey-feedback/${props.match.params.id}`,state:{questionId:data.question_id,feedbackQuestions:FeedbackQuestions}})} style={{background:"rgba(0, 92, 135, 1)", width:"285px",border:"none",color:"white",height:"50px",borderRadius:"6px", fontFamily:"Rubik-Medium",fontSize:"18px",lineHeight:"24px"}}>View Feedback</button></div>
                                </AnswerWrapper>
                              </div>
                            </div>
                          </QuestionsCard> :
                          data.type === "multiple-choice" ?
                            <QuestionsCard key={index} widthValue={data.point_status?'67.4%':'100%'} isWordBreak={true}>
                              <div className="question">
                                <div className='que'>{`Q${data.weight}. ${data.question}`}</div>
                                <div className="que-type">{"MCQ's"}</div>
                              </div>
                              
                              <div className="body">
                                <div className="circularPercentage">
                                  <CircleNew width="170px" height="170px">
                                    <CircularProgressBar
                                      strokeWidth="17"
                                      sqSize="170"
                                      percentage={data.score_percentage}
                                      color="#005C87"
                                      textValue="Average Score"
                                    />
                                  </CircleNew>
                                  <div className='score-details'>
                                    <div>
                                      <div className='row'>
                                        <div className='circle'></div>
                                        <div><span className='count'>{data.response_count}</span> <span className='resp'>Responses</span></div>
                                      </div>
                                      {/* <div className='row'>
                                        <div className='circle'></div>
                                        <div><span className='count'>{data.skipped_response_count}</span> <span className='resp'>Skipped Questions</span></div>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="answers">
                                  <AnswerWrapper padding="35px 35px 37px 37px">
                                    {data?.answers?.map((ans, index) => (
                                      <AnswerCard key={index} color={ans.color} display="flex" margin="10px" border="1">
                                        <div className="wrapper">
                                          <div className="optionValue">{ans.value}</div>
                                          <div className="progressGraph">
                                            <ProgressBar radius="1" height={"35px"} backgroundActive={getColorByIndex(index)} background={ans.background_color} width={Math.round(ans?.selected_answer_percentage)} paddingRight={ans?.selected_answer_percentage?"15px":"0px"} >
                                              <div className="progress" onMouseEnter={() => handleMouseEnter(index, data.question_id)} onMouseLeave={() => handleMouseLeave(index, data.question_id)}  ></div>
                                              {activeOption.displayPercentage && index == activeOption.showOption&& activeOption.selectdQuestionId===data.question_id&&<span className='showPercent'>{(Math.round(ans?.selected_answer_percentage))}%</span>}
                                            </ProgressBar>
                                          </div>
                                        </div>
                                      </AnswerCard>))}
                                    <div className="line">
                                      <div className="fill"></div>
                                      <div className="borderLine">
                                        <div className="value">0</div>
                                        <div className="value">20%</div>
                                        <div className="value">40%</div>
                                        <div className="value">60%</div>
                                        <div className="value">80%</div>
                                        <div className="value">100%</div>
                                      </div>
                                    </div>
                                  </AnswerWrapper>
                                </div>
                              </div>
                            </QuestionsCard> :
                            data.type === "opinion-scale" ?
                              <QuestionsCard key={index}  type={data.graph_type === 3} isWordBreak={true}>
                                <div className="question">
                                  <div className='que'>{`Q${data.weight}. ${data.question}`}</div>
                                  <div className="que-type">{"Opinion Scale"}</div>
                                </div>
                                <div className="body">
                                  <div className="circularPercentage">
                                    <CircleNew width="170px" height="170px">
                                      <CircularProgressBar
                                        strokeWidth="17"
                                        sqSize="170"
                                        percentage={data.score_percentage}
                                        color="#005C87"
                                        textValue="Average Score"
                                      />
                                    </CircleNew>
                                    <div className='score-details'>
                                      <div>
                                        <div className='row'>
                                          <div className='circle'></div>
                                          <div><span className='count'>{data.response_count}</span> <span className='resp'>Responses</span></div>
                                        </div>
                                        {/* <div className='row'>
                                          <div className='circle'></div>
                                          <div><span className='count'>{data.skipped_response_count}</span> <span className='resp'>Skipped Questions</span></div>
                                        </div> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="answers">
                                    <AnswerWrapper>
                                      {graphRender(data?.answers, data.question_id, index)}
                                      <div className='opinion-scale'>
                                        <div className='score'><div style={{width:"15px",height:"15px", background:"#F4AAA9"}}></div><span className='score-text'>Detactor</span></div>
                                        <div className='score'><div style={{width:"15px",height:"15px", background:"#F1C977"}}></div><span className='score-text'>Passive</span></div>
                                        <div className='score'><div style={{width:"15px",height:"15px", background:"#76AB78"}}></div><span className='score-text'>Promoter</span></div>
                                      </div>
                                    </AnswerWrapper>
                                  </div>
                                  
                                </div>
                                
                              </QuestionsCard> :
                              data.type === "likert-scale" ?
                                <QuestionsCard key={index}  type={data.graph_type === 3} isWordBreak={true}>
                                  <div className="question">
                                    <div className='que'>{`Q${data.weight}. ${data.question}`}</div>
                                    <div className="que-type">{"Likert Scale"}</div>
                                  </div>
                                  <div className="body">
                                    <div className="circularPercentage">
                                      <CircleNew width="170px" height="170px">
                                        <CircularProgressBar
                                          strokeWidth="17"
                                          sqSize="170"
                                          percentage={data.score_percentage}
                                          color="#005C87"
                                          textValue="Average Score"
                                        />
                                      </CircleNew>
                                      <div className='score-details'>
                                        <div>
                                          <div className='row'>
                                            <div className='circle'></div>
                                            <div><span className='count'>{data?.response_count}</span> <span className='resp'>Responses</span></div>
                                          </div>
                                          {/* <div className='row'>
                                            <div className='circle'></div>
                                            <div><span className='count'>{data.skipped_response_count}</span> <span className='resp'>Skipped Questions</span></div>
                                          </div> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="answers">
                                      <AnswerWrapper>
                                        {graphRender(data?.answers, data.question_id, index)}
                                      </AnswerWrapper>
                                    </div>
                                    
                                  </div>
                                  
                                </QuestionsCard> :
                                <QuestionsCard key={index} isWordBreak={true}>
                                  <div className="question">
                                    <div className='que'>{`Q${data.weight}. ${data.question}`}</div>
                                    <div className="que-type">{data.type}</div>
                                  </div>
                                  <div className="body">
                                    <div className="circularPercentage">
                                      <CircleNew width="170px" height="170px">
                                        <CircularProgressBar
                                          strokeWidth="17"
                                          sqSize="170"
                                          percentage={data.score_percentage}
                                          color="#005C87"
                                          textValue="Average Score"
                                        />
                                      </CircleNew>
                                      <div className='score-details'>
                                        <div>
                                          <div className='row'>
                                            <div className='circle'></div>
                                            <div><span className='count'>{data.response_count}</span> <span className='resp'>Responses</span></div>
                                          </div>
                                          {/* <div className='row'>
                                            <div className='circle'></div>
                                            <div><span className='count'>{data.skipped_response_count}</span> <span className='resp'>Skipped Questions</span></div>
                                          </div> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="answers">
                                      <AnswerWrapper>
                                        {data?.answers?.map((ans, index) => (
                                          <AnswerCard key={index} color={ans.graph_color} margin="0px">
                                            <div className="wrapper">
                                              <div className="answerCard">
                                                <div className="answerDataValue">
                                                  <div className="first">
                                                    <div className="optionName">
                                                      {ans.answer}
                                                    </div>
                                                  </div>
                                                  <div className="second">{Math.round(ans?.selected_answer_percentage)}%</div>
                                                </div>
                                              </div>
                                              <ProgressBar height={"16px"} backgroundActive={ans.graph_color} background={ans.background_color} width={Math.round(ans?.selected_answer_percentage)} paddingRight="0px">
                                                <div className="progress"></div>
                                              </ProgressBar>
                                            </div>
                                          </AnswerCard>))}
                                      </AnswerWrapper>
                                    </div>
                                  </div>
                                </QuestionsCard>
                  ))}
                </div>}
            </AssesmentWrapper>}

          </HeaderWrapper>
        </MainEventsContainer>
      </OverlayDashboard>
    </React.Fragment>
  )
}

QuestionInsight.propTypes = {
  match: PropTypes.object,
  companyInfo: PropTypes.object.isRequired,
  history: PropTypes.object,
  userPermissions: PropTypes.array,
  departmentDetails:PropTypes.array,
  locationDetails:PropTypes.array,
  role: PropTypes.string,
};

const mapStateToProps = (state) => ({
  userPermissions: state.profileData.userPermissions,
  departmentDetails: state.companyDashboard.companyPeopleDepartment,
  locationDetails: state.companyDashboard.companyPeopleLocation,
});


export default withRouter(connect(mapStateToProps, null)(QuestionInsight));
